import React from 'react';
import './aboutus.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory, faUsers, faBullseye, faRocket } from '@fortawesome/free-solid-svg-icons';

const AboutUs = () => {
  return (
    <div className="container" id="about">
      <div className="about-us">
        <h2>Wer Wir Sind</h2>
        <div className="about-grid">
          <div className="about-card" data-aos="fade-up">
            <div className="icon-wrapper">
              <FontAwesomeIcon icon={faHistory} className="icon" />
            </div>
            <h3>Unsere Geschichte</h3>
            <p>
              Unsere Webagentur in Bad Hersfeld hat sich darauf spezialisiert, innovative und individuelle Weblösungen zu entwickeln. Seit unserer Gründung haben wir zahlreichen Unternehmen in Bad Hersfeld und Umgebung geholfen, ihre Online-Präsenz zu optimieren.
            </p>
          </div>
          <div className="about-card" data-aos="fade-up" data-aos-delay="100">
            <div className="icon-wrapper">
              <FontAwesomeIcon icon={faUsers} className="icon" />
            </div>
            <h3>Unser Team</h3>
            <p>
              Unser Team besteht aus erfahrenen Entwicklern und Designern, die sich auf den Einsatz neuester Webtechnologien spezialisiert haben. Wir arbeiten eng mit lokalen Unternehmen zusammen, um maßgeschneiderte Lösungen zu bieten.
            </p>
          </div>
          <div className="about-card" data-aos="fade-up" data-aos-delay="200">
            <div className="icon-wrapper">
              <FontAwesomeIcon icon={faBullseye} className="icon" />
            </div>
            <h3>Unsere Mission & Vision</h3>
            <p>
              Unsere Mission ist es, durch individuelle Weblösungen echten Mehrwert zu schaffen. Wir setzen auf innovative Technologien und nachhaltige Lösungen, um den langfristigen Erfolg unserer Kunden zu sichern.
            </p>
          </div>
          <div className="about-card" data-aos="fade-up" data-aos-delay="300">
            <div className="icon-wrapper">
              <FontAwesomeIcon icon={faRocket} className="icon" />
            </div>
            <h3>Warum Wir?</h3>
            <p>
              Wir bieten ansprechende und funktionale Weblösungen, die auf die Anforderungen von Unternehmen aus Bad Hersfeld zugeschnitten sind. Unser Ansatz kombiniert technisches Know-how mit kreativem Design.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
