import React from 'react';
import './cta.css';

const CTA = () => {
  return (
    <div className="cta-container" data-aos="fade-up">
      <div className="cta-content">
        <h2 data-aos="fade-up" data-aos-delay="100">Starten Sie Ihr Projekt Noch Heute!</h2>
        <div className="cta-buttons" data-aos="fade-up" data-aos-delay="200">
          <a href="/#contactus" className="cta-button">Fordern Sie ein Angebot an</a>
          <a href="#contactus" className="cta-button">Vereinbaren Sie einen Beratungstermin</a>
      
            <a href="/rechtliches" className="cta-button">Impressum und Datenschutz</a>
        </div>
      </div>
    
    </div>
  );
}

export default CTA;
