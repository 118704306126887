import React from 'react';
import './blog.css';

const Blog = () => {
  return (
    <div className="container" id='blog'>
      <div className="blog">
        <h2 data-aos="fade-up">Neuigkeiten & Tipps</h2>
        <div className="blog-content">
          <section className="blog-section" data-aos="fade-up" data-aos-delay="100">
            <h3>Aktuelle Trends im Webdesign</h3>
            <p>Erfahren Sie mehr über die neuesten Entwicklungen und Trends im Webdesign. Von innovativen Layouts bis zu fortschrittlichen Technologien – bleiben Sie immer am Puls der Zeit.</p>
            <a href="/trendsWebdesign" className="read-more">Mehr erfahren</a>
          </section>
          <section className="blog-section" data-aos="fade-up" data-aos-delay="200">
            <h3>Tipps zur Suchmaschinenoptimierung</h3>
            <p>Unsere Experten teilen ihre besten Tipps zur Suchmaschinenoptimierung (SEO). Lernen Sie, wie Sie Ihre Website so optimieren können, dass sie besser gefunden wird und mehr Traffic erhält.</p>
            <a href="/SEOArticle" className="read-more">Mehr erfahren</a>
          </section>
          <section className="blog-section" data-aos="fade-up" data-aos-delay="400">
            <h3>Parallaxscrolling: Mehr als nur Eyecandy</h3>
            <p>Durchstöbern Sie unsere Anleitungen und Tutorials, um wertvolle Einblicke und Schritt-für-Schritt-Anleitungen zu erhalten. Verbessern Sie Ihre Fähigkeiten und erweitern Sie Ihr Wissen mit unseren umfassenden Guides.</p>
            <a href="/ParallaxScrolling" className="read-more">Mehr erfahren</a>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Blog;
