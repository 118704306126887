import React from 'react';
import './contact.css';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';

const Contact = () => {
  return (
   <div className="container" id="contactus">

      <div className="row justify-content-center">
        <div className="col-12 col-md-10 contact-card" >
          <h1 className="contact-title text-center">Lassen Sie Sich Kostenlos Beraten</h1>
<br></br>
          <div className="row contact-content">
            {/* Kontaktinformationen */}
            <div className="col-12 col-md-4 contact-info-item text-center">
              <FaMapMarkerAlt className="contact-icon" />
              <p>Dudenstraße 6, 36251 Bad Hersfeld</p>
            </div>

            <div className="col-12 col-md-4 contact-info-item text-center">
              <FaPhoneAlt className="contact-icon" />
              <p>+49 6621 4374982</p>
            </div>

            <div className="col-12 col-md-4 contact-info-item text-center">
              <FaEnvelope className="contact-icon" />
              <p>support@exabyte-systems.com</p>
            </div>
          </div>

          <div className="row">
            {/* Kontaktformular */}
            <div className="col-12 contact-form">
              <form action="http://localhost/contact.php" method="POST">
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" className="form-control" required />

                <label htmlFor="email">E-Mail:</label>
                <input type="email" id="email" name="email" className="form-control" required />

                <label htmlFor="message">Nachricht:</label>
                <textarea id="message" name="message" className="form-control" required></textarea>

                <div className="form-check mt-3">
                  <input type="checkbox" className="form-check-input" id="checkbox" name="checkbox" required />
                  <label htmlFor="checkbox" className="form-check-label">
                    Ich habe die Datenschutzerklärung gelesen und akzeptiere sie.
                  </label>
                </div>

                <button type="submit" className="btn btn-primary mt-3 w-100">Absenden</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    
    </div>

 
  );
};

export default Contact;
