import React, { useState, useEffect } from 'react';
import './darkmode.css';

const DarkMode = () => {
  const [darkMode, setDarkMode] = useState(true);

  // Effekt, um den Darkmode-Klasse zu togglen
  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [darkMode]);

  return (
    <div className="toggle-container">
      <span style={{ color: darkMode ? 'white' : 'black' }}>🌞</span>
      <label className="switch">
        <input
          type="checkbox"
          onChange={() => setDarkMode(!darkMode)}
          checked={darkMode}
        />
        <span className="slider round"></span>
      </label>
      <span style={{ color: darkMode ? 'white' : 'black' }}>🌚</span>
    </div>
  );
};

export default DarkMode;
