import React from 'react';
import './Hero.css';
import HeroImage from '../../assets/hero.jpg'; // Stelle sicher, dass du das Bild im richtigen Pfad hast

const Hero = () => {
    return (
        <div id="hero" className="hero">
            <div className="hero-overlay"></div>
            <div className="hero-content">
                {/* Erster Teil der h1 Überschrift */}
                <h1 className="hero-title-part1">Willkommen bei </h1>
                
                {/* Zweiter Teil der h1 Überschrift */}
                <h1 className="hero-title-part2">Exabyte Systems</h1>
                
                <p className="hero-description">
Professionelle Online-Lösungen aus Bad Hersfeld - Ihre Ideen unser Know-how
</p>

                <a href="#services" className="cta-button">Mehr erfahren</a>
            </div>
            <div className="hero-image-container">
                <img src={HeroImage} alt="Exabyte Webservices ihre Webagentur für gute Websiten und Webdesign in Bad Hersfeld" className="hero-image" />
            </div>
        </div>
    );
}

export default Hero;
