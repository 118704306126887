import React from 'react';
import { Line } from 'react-chartjs-2';
import './SEO.css';
import image1 from '../../assets/seo.jpg';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Registrierung der Chart.js-Komponenten
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const SEO = () => {
  const data = {
    labels: ['Monat 1', 'Monat 2', 'Monat 3', 'Monat 4', 'Monat 5', 'Monat 6'],
    datasets: [
      {
        label: 'Organischer Traffic',
        data: [100, 150, 220, 350, 500, 700],
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        fill: true,
        tension: 0.4,
      },
      {
        label: 'Bezahlte Suchanzeigen',
        data: [50, 80, 120, 180, 240, 320],
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        fill: true,
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Verlauf des Website-Traffics über 6 Monate',
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw} Besucher`;
          },
        },
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: 'Besucheranzahl in Relation',
        },
      },
      x: {
        title: {
          display: true,
          text: 'Monate',
        },
      },
    },
  };

  return (
    <div className="container" id="seo">
      <div className="services">
<h1 >SEO in Bad Hersfeld - Exabyte Webservices</h1>

        <section className="seo-section" data-aos="fade-up">
          <h4 className="seo-heading">Warum SEO wichtig ist</h4>
          <p className="seo-text">
            SEO (Search Engine Optimization) ist entscheidend, um die Sichtbarkeit Ihrer Website in Suchmaschinen zu verbessern. 
            Durch eine gezielte Optimierung können Sie mehr potenzielle Kunden erreichen, den Traffic steigern 
            und langfristig die Conversion-Rate erhöhen.
          </p>
          <img src={image1} alt="Webdesign Header" className="intro-image" />

        </section>

        {/* Erklärung von SEO-Maßnahmen */}
        <section className="seo-section" data-aos="fade-up" data-aos-delay="100">
          <h4 className="seo-heading">Unsere SEO-Strategien im Detail</h4>
          <p className="seo-text">
            Wir bieten eine umfassende SEO-Beratung, die von der technischen Optimierung Ihrer Website bis hin zur 
            Erstellung von hochwertigen Inhalten reicht. Unsere Maßnahmen umfassen:
          </p>
          <ul className="seo-list">
            <li>Technische SEO: Verbesserung der Ladegeschwindigkeit, Mobile-Optimierung, Fehlerbehebung.</li>
            <li>On-Page SEO: Keyword-Optimierung, Meta-Beschreibungen, URL-Struktur.</li>
            <li>Content-Strategien: Erstellung und Optimierung von Inhalten, die gezielt auf Ihre Zielgruppe abgestimmt sind.</li>
            <li>Linkbuilding: Aufbau qualitativ hochwertiger Backlinks, um die Autorität Ihrer Website zu stärken.</li>
          </ul>
        </section>

        {/* Diagramm */}
        <section className="seo-section seo-chart-section" data-aos="fade-up" data-aos-delay="200">
          <h4 className="seo-heading">Traffic-Entwicklung mit SEO-Strategie</h4>
          <p className="seo-text">
            Diese Grafik zeigt den potenziellen Anstieg des Traffics durch verschiedene Maßnahmen. Eine umfassende Strategie, 
            die organischen und bezahlten Traffic kombiniert, führt oft zu einem deutlichen Wachstum.
          </p>
          <div className="seo-chart">
            <Line data={data} options={options} />
          </div>
        </section>

        {/* SEO als langfristige Investition */}
        <section className="seo-section" data-aos="fade-up" data-aos-delay="300">
          <h4 className="seo-heading">Warum SEO eine nachhaltige Investition ist</h4>
          <p className="seo-text">
            Im Gegensatz zu bezahlten Werbeanzeigen, die nur kurzfristig wirken, bietet SEO eine nachhaltige Steigerung Ihrer 
            Sichtbarkeit. Durch kontinuierliche Optimierung können Sie langfristig stabile Ergebnisse erzielen, was sich auch 
            positiv auf Ihre Conversion-Rate auswirkt. Eine gut durchdachte SEO-Strategie verbessert nicht nur den Traffic, 
            sondern auch die Qualität der Besucher, da Sie gezielt Nutzer ansprechen, die wirklich an Ihren Angeboten interessiert sind.
          </p>
        </section>

        {/* Call-to-Action */}
        <section className="seo-section seo-cta-section text-center" data-aos="fade-up" data-aos-delay="400">
          <h4 className="seo-heading">Erleben Sie die Vorteile von SEO</h4>
          <p className="seo-text">
            Lassen Sie uns gemeinsam Ihre SEO-Strategie entwickeln. Kontaktieren Sie uns, um mehr darüber zu erfahren, 
            wie wir Ihnen helfen können, Ihre Website sichtbarer und erfolgreicher zu machen.
          </p>
          <a href='../#contactus' className="seo-cta-button btn btn-primary btn-lg">Kontaktieren Sie Uns</a>
        </section>
      </div>
    </div>
  );
};

export default SEO;
