import React from 'react';
import './Services.css';
import { FaCode, FaSearch, FaWrench, FaShoppingCart, FaPlug, FaServer } from 'react-icons/fa';

const Services = () => {
  return (
    <div className="container" id='services'>
      <div className="services">
        <h3 data-aos="fade-up">Unsere Dienstleistungen</h3>
        <div className="service-blocks">
          <div className="service-block" data-aos="fade-up" data-aos-delay="100">
            <FaCode className="service-icon" />
            <h5>Website-Design & -Entwicklung</h5>
            <p>Maßgeschneiderte Weblösungen, die sowohl ästhetisch ansprechend als auch funktional sind.</p>
            <a href="/design" className="read-more">Mehr erfahren</a>
          </div>
          <div className="service-block" data-aos="fade-up" data-aos-delay="200">
            <FaSearch className="service-icon" />
            <h5>Suchmaschinen-optimierung (SEO)</h5>
            <p>Optimierung Ihrer Website für bessere Sichtbarkeit und höhere Platzierungen in Suchmaschinen.</p>
            <a href="/SEO" className="read-more">Mehr erfahren</a>
          </div>
          <div className="service-block" data-aos="fade-up" data-aos-delay="300">
            <FaWrench className="service-icon" />
            <h5>Wartung & Support</h5>
            <p>Kontinuierliche Wartung und technische Unterstützung, um Ihre Website stets aktuell und sicher zu halten.</p>
            <a href="/support" className="read-more">Mehr erfahren</a>
          </div>
          <div className="service-block" data-aos="fade-up" data-aos-delay="400">
            <FaShoppingCart className="service-icon" />
            <h5>E-Commerce-Lösungen</h5>
            <p>Komplette E-Commerce-Pakete, die Ihren Online-Shop optimieren und Ihre Verkaufsprozesse unterstützen.</p>
            <a href="/ecommerce" className="read-more">Mehr erfahren</a>

          </div>
          <div className="service-block" data-aos="fade-up" data-aos-delay="500">
            <FaPlug className="service-icon" />
            <h5>Individuelle Webanwendungen</h5>
            <p>Entwicklung maßgeschneiderter Webanwendungen, die auf die spezifischen Anforderungen Ihres Unternehmens abgestimmt sind.</p>
            <a href="/individual" className="read-more">Mehr erfahren</a>

          </div>
          <div className="service-block" data-aos="fade-up" data-aos-delay="600">
            <FaServer className="service-icon" />
            <h5>Webhosting & Domainverwaltung</h5>
            <p>Verlässliches Hosting und umfassende Verwaltung Ihrer Domain, damit Ihre Website immer online ist.</p>
            <a href="/webhosting" className="read-more">Mehr erfahren</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
