import React, { useState } from 'react';
import './faq.css';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleIndex = index => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: 'Was kostet eine Website?',
      answer: 'Die Kosten für eine Website können variieren, abhängig von Faktoren wie Design, Funktionalität und Umfang. Wir bieten maßgeschneiderte Pakete an, die auf Ihre spezifischen Bedürfnisse abgestimmt sind. Kontaktieren Sie uns für ein individuelles Angebot.'
    },
    {
      question: 'Wie lange dauert die Erstellung?',
      answer: 'Die Dauer der Erstellung einer Website hängt von der Komplexität des Projekts ab. In der Regel dauert es zwischen 4 bis 8 Wochen, um eine Website zu entwickeln, einschließlich Design, Entwicklung und Testphase.'
    },
    {
      question: 'Was passiert nach dem Launch?',
      answer: 'Nach dem Launch Ihrer Website bieten wir Wartungs- und Support-Services an, um sicherzustellen, dass alles reibungslos funktioniert. Dazu gehören regelmäßige Updates, Sicherheitsüberprüfungen und technische Unterstützung.'
    },
    {
      question: 'Wie kann ich meine Website aktualisieren?',
      answer: 'Sie können Ihre Website entweder selbst aktualisieren, wenn Sie ein Content-Management-System (CMS) nutzen, oder wir können regelmäßige Updates für Sie durchführen. Kontaktieren Sie uns für Unterstützung bei der Aktualisierung Ihrer Inhalte.'
    }
  ];

  return (
    <div className="container" id="faq">
      <div className="faqs">
        <h2>Häufige Fragen</h2>
        <div className="faq-list">
          {faqs.map((faq, index) => (
            <div 
              key={index} 
              className={`faq-item ${activeIndex === index ? 'active' : ''}`} 
              onClick={() => toggleIndex(index)}
            >
              <div className="faq-question">{faq.question}</div>
              <div className="faq-answer">{faq.answer}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FAQ;
