import React, { useState } from 'react';
import './Comparison.css';
import { FaCheck, FaTimes, FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Icons für Häkchen, Kreuze und Dropdowns

const Comparison = () => {
  // State für das Öffnen und Schließen der Dropdowns
  const [openDropdown, setOpenDropdown] = useState({});

  const toggleDropdown = (index) => {
    setOpenDropdown((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <div className="container" id="comparison">
      <div className="services">
        <h3 data-aos="fade-up">Vergleich: Exabyte Webservices vs. Andere Agenturen</h3>
        <div className="comparison-table-wrapper" data-aos="fade-up" data-aos-delay="100">
          <table className="comparison-table" >
            <thead>
              <tr>
                <th>Kriterium</th>
                <th>Exabyte Webservices</th>
                <th>Andere Agenturen</th>
              </tr>
            </thead>
            <tbody>
              {/* Beispiel für jedes Dropdown */}
              <tr>
                <td onClick={() => toggleDropdown(1)} className="dropdown-title">
                  Preis-Leistungs-Verhältnis {openDropdown[1] ? <FaChevronUp /> : <FaChevronDown />}
                </td>
                <td><FaCheck className="icon-check" /></td>
                <td><FaTimes className="icon-times" /></td>
              </tr>
              {openDropdown[1] && (
                <tr>
                  <td colSpan="3" className="dropdown-content">
                    Unsere Agentur bietet flexible und transparente Preisgestaltung, während andere Agenturen oft starre und undurchsichtige Modelle verwenden.
                  </td>
                </tr>
              )}
              
              <tr>
                <td onClick={() => toggleDropdown(2)} className="dropdown-title">
                  Maßgeschneiderte Lösungen {openDropdown[2] ? <FaChevronUp /> : <FaChevronDown />}
                </td>
                <td><FaCheck className="icon-check" /></td>
                <td><FaTimes className="icon-times" /></td>
              </tr>
              {openDropdown[2] && (
                <tr>
                  <td colSpan="3" className="dropdown-content">
                    Wir bieten vollständig an die Kundenbedürfnisse angepasste Lösungen, während andere Agenturen häufig Standardpakete verwenden.
                  </td>
                </tr>
              )}

              <tr>
                <td onClick={() => toggleDropdown(3)} className="dropdown-title">
                  Reaktionszeit (24/7 Support) {openDropdown[3] ? <FaChevronUp /> : <FaChevronDown />}
                </td>
                <td><FaCheck className="icon-check" /></td>
                <td><FaTimes className="icon-times" /></td>
              </tr>
              {openDropdown[3] && (
                <tr>
                  <td colSpan="3" className="dropdown-content">
                    Wir bieten 24/7 Support, um schnelle Hilfe zu gewährleisten, während andere Agenturen oft nur eingeschränkte Geschäftszeiten haben.
                  </td>
                </tr>
              )}

              <tr>
                <td onClick={() => toggleDropdown(4)} className="dropdown-title">
                  Technologie {openDropdown[4] ? <FaChevronUp /> : <FaChevronDown />}
                </td>
                <td><FaCheck className="icon-check" /></td>
                <td><FaCheck className="icon-check" /></td> {/* Beide haben Häkchen */}
              </tr>
              {openDropdown[4] && (
                <tr>
                  <td colSpan="3" className="dropdown-content">
                    Unsere Agentur arbeitet mit modernen Technologien wie zum Beispiel ReactJS, während andere Agenturen oft bewährte, aber ältere Tools nutzen.
                  </td>
                </tr>
              )}

              <tr>
                <td onClick={() => toggleDropdown(5)} className="dropdown-title">
                  Sicherheit {openDropdown[5] ? <FaChevronUp /> : <FaChevronDown />}
                </td>
                <td><FaCheck className="icon-check" /></td>
                <td><FaTimes className="icon-times" /></td>
              </tr>
              {openDropdown[5] && (
                <tr>
                  <td colSpan="3" className="dropdown-content">
                    Unsere Agentur hat eigene Sicherheitsexperten und bietet höchste Sicherheitsstandards für Ihre Projekte.
                  </td>
                </tr>
              )}

              <tr>
                <td onClick={() => toggleDropdown(6)} className="dropdown-title">
                  Langfristiger Support {openDropdown[6] ? <FaChevronUp /> : <FaChevronDown />}
                </td>
                <td><FaCheck className="icon-check" /></td>
                <td><FaTimes className="icon-times" /></td>
              </tr>
              {openDropdown[6] && (
                <tr>
                  <td colSpan="3" className="dropdown-content">
                 Bei Exabyte Systems haben Sie haben die Möglichkeit Langfristigen Support für Ihr Projekt zu bekommen, während man bei anderen Agenturen oft nicht diese Möglichkeit hat.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Comparison;
