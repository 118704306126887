import React from 'react';
import './Pricing.css';

const Pricing = () => {
  return (
    <div className="container" id='pricing'>
      <div className="pricing">
        <h2 data-aos="fade-up">Unsere Preise & Pakete</h2>
        <div className="pricing-info">
          <div className="pricing-section" data-aos="fade-up" data-aos-delay="100">
            <div className="pricing-header">
              <h3>Transparent & Fair</h3>
              <p>Wir bieten eine transparente Preisgestaltung ohne versteckte Kosten. So wissen Sie genau, wofür Sie bezahlen.</p>
            </div>
          </div>
          <div className="pricing-section" data-aos="fade-up" data-aos-delay="200">
            <div className="pricing-header">
              <h3>Pakete für Kleinunternehmer</h3>
              <p>Unsere Pakete sind ideal für kleine Unternehmen und Start-ups. Wir bieten Ihnen flexible Optionen, die sich an Ihre Bedürfnisse und Ihr Budget anpassen.</p>
            </div>
          </div>
          <div className="pricing-section" data-aos="fade-up" data-aos-delay="300">
            <div className="pricing-header">
              <h3>Individuelle Angebote für größere Projekte</h3>
              <p>Für größere Projekte erstellen wir maßgeschneiderte Angebote, die genau auf Ihre Anforderungen abgestimmt sind. Lassen Sie uns wissen, wie wir Ihnen helfen können.</p>
            </div>
          </div>
          <div className="pricing-section" data-aos="fade-up" data-aos-delay="400">
            <div className="pricing-header">
              <h3>Zusätzliche Dienstleistungen & Optionen</h3>
              <p>Ergänzend zu unseren Standardpaketen bieten wir zusätzliche Dienstleistungen wie SEO-Optimierung, Webhosting und regelmäßige Wartung an.</p>
            </div>
          </div>
        </div>
        <div className="cta-section" data-aos="fade-up" data-aos-delay="500">
          <h3>Interessiert an einem Angebot?</h3>
          <p>Kontaktieren Sie uns für ein individuelles Angebot, das genau auf Ihre Bedürfnisse zugeschnitten ist.</p>
          <a href="#contactus" className="cta-button">Jetzt Kontakt aufnehmen</a>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
