import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'aos/dist/aos.css'; // Importiere AOS CSS
import AOS from 'aos'; // Importiere AOS JS

// Initialisiere AOS
AOS.init({
  duration: 500, // Dauer der Animationen in Millisekunden
  offset:40,
  once: true, // Animation nur einmal
  mirror: false, // Animationen werden vertauscht
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
